import { Page } from "components/Page";
import React from "react";

const ContactPage = () => {
  return (
    <Page>
      <section className="container mx-auto">
        <div className="md:ml-24 mt-24">
          <h3 className="mt-4">Partnerships / Brand Tours / New Business</h3>
          <p>
            Please email{" "}
            <a href="mailto:danny@fresh2deathuk.com">danny@fresh2deathuk.com</a>
          </p>
          <h3 className="mt-4">Jobs / Societies / Sports Teams</h3>
          <p>
            Please email{" "}
            <a href="mailto:ben@fresh2deathuk.com">ben@fresh2deathuk.com</a>
          </p>
          <h3 className="mt-4">
            Content Creation / Social Media collaborations
          </h3>
          <p>
            Please email{" "}
            <a href="mailto:ella@fresh2deathuk.com">ella@fresh2deathuk.com</a>
          </p>
          <h3 className="mt-4">
            For Lost Property please contact the venues directly:
          </h3>

          <div className="mt-4">
            <h4>HiFi Club</h4>
            <p>0113 242 7353</p>
          </div>
          <div className="mt-2">
            <h4>The Warehouse</h4>
            <p> 0113 426 5078</p>
          </div>
          <div className="mt-2">
            <h4>The Old Red Bus Station</h4>
            <p>0113 345 1661</p>
          </div>
          <div className="mt-2">
            <h4>Freedom Mills</h4>
            <p>
              <a href="mailto:info@freedommills.co.uk">
                info@freedommills.co.uk
              </a>
            </p>
          </div>
          <div className="mt-2">
            <h4>Beaver Works</h4>
            <p>07943 814 078</p>
          </div>
          <h3 className="mt-4">Join us on social media</h3>
          <div>
            Fresh2Death Facebook{" "}
            <a href="https://www.facebook.com/fresh2deathuk">fresh2deathuk</a>
          </div>
          <div>
            Fresh2Death Instagram{" "}
            <a href="https://www.instagram.com/fresh2deathuk/">
              @fresh2deathuk
            </a>
          </div>
          <div>
            Fresh2Death TikTok{" "}
            <a href="https://www.tiktok.com/@fresh2deathuk">@fresh2deathuk</a>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default ContactPage;
